import { IParagraphBackgroundProps } from "./ParagraphBackgroundProps";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";

interface IBackgroundStyles {
  color: string | null;
  repeat: string | undefined;
  image: string;
  foreground: string | undefined;
  size: string;
  position: string;
  fluid: unknown; // Replace with the correct type if known
}

export const getBackgroundStyles = (
  props: IParagraphBackgroundProps
): IBackgroundStyles | null => {
  const paragraph = props;
  if (!paragraph) {
    return null;
  }
  let image = "none";
  let fluid;
  const media = paragraph.relationships.field_media;
  const noRepeat = paragraph.field_repeat === "no-repeat";
  if (media && media.relationships.field_media_file) {
    image = `url(${media.relationships.field_media_file.publicUrl})`;
    fluid = getImage(
      media.relationships.field_media_file.childImageSharp as any
    );
  }
  return {
    color: paragraph.field_background_color
      ? paragraph.field_background_color.color
      : null,
    repeat: paragraph.field_repeat,
    image: image,
    foreground: paragraph.field_foreground_color,
    size: noRepeat ? "cover" : "auto",
    position: noRepeat ? "center" : "inherit",
    fluid: fluid,
  };
};

export const fragment = graphql`
  fragment ParagraphBackground on paragraph__background {
    id
    field_background_color {
      color
    }
    field_foreground_color
    field_repeat
    relationships {
      field_media {
        relationships {
          field_media_file {
            gatsbyImage(width: 640, placeholder: BLURRED, formats: [AUTO, WEBP])
          }
        }
      }
    }
  }
`;
