import * as React from "react";
import { IBrandPageProps } from "./BrandPageProps";
import { graphql } from "gatsby";
import { Router } from "@reach/router";
import Layout from "../../components/global/Layout/Layout";
import IBrand from "../../@types/IBrand";
import BrandDefault from "../../components/brand/BrandDefault/BrandDefault";
import BrandSearch from "../../components/brand/BrandSearch/BrandSearch";
import BrandOffers from "../../components/brand/BrandOffers/BrandOffers";
import { addPageTypeInGTMDataLayer } from "../../utils/datalayers";
import { addKoddiPageTag } from "../../utils/KoddiTagsGTM";
import { Storage } from "../../utils/storage";
import { Constants } from "../../../src/@types/Constants";

const BrandPage = (data: IBrandPageProps): JSX.Element => {
  const _brand: IBrand = data.data ? data.data.brand : null;
  const _siteOffers = data?.data?.siteOffers?.edges.length
    ? data?.data.siteOffers?.edges[0].node?.relationships?.field_node_offers
    : [];
  const hotelSearchFilters = data.data?.allRlhsite.edges.length
    ? data.data.allRlhsite.edges[0].node.field_filter_selected
    : [];
  // const _paragraphs = [..._brand.relationships.paragraphs.map(getParagraph)];
  React.useEffect(() => {
    try {
      addPageTypeInGTMDataLayer("brand page");
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }, []);

  React.useEffect(() => {
    if (_brand?.brand_code) {
      const brandInfo = {
        brand_code: _brand?.brand_code,
      };
      const brandInfoString = JSON.stringify(brandInfo);
      Storage.SetLocalStorageValue(Constants.BRAND_BIAS_KEY, brandInfoString);
    }
  }, [_brand]);

  React.useEffect(() => {
    try {
      addKoddiPageTag(data.location, "BrandPage");
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }, []);

  return (
    <Layout brand={_brand} showLogo isBrandPage>
      <Router basepath={_brand.path.alias}>
        <BrandDefault
          path="/*"
          brand={_brand}
          location={data.location}
          siteOffers={_siteOffers}
        />
        <BrandSearch
          path="/hotel-search"
          brand={_brand}
          location={data.location}
          hotelSearchFilters={hotelSearchFilters}
        />
        <BrandOffers path="/offers" brand={_brand} />
      </Router>
      {/* <SocialMedia /> */}
    </Layout>
  );
};

export const query: void = graphql`
  query ($slug: String!) {
    brand(path: { alias: { eq: $slug } }) {
      id
      name
      field_meta_description
      brand_code
      brand_id: drupal_internal__id
      description {
        summary
        processed
        value
      }
      field_brand_stripe_color {
        color
      }
      field_color {
        color
      }
      field_logo_alt_text
      field_logo_alternate_alt_text
      path {
        alias
      }
      relationships {
        field_brand_stripe_logo {
          publicUrl
        }
        field_logo {
          publicUrl
        }
        field_logo_alternate {
          uri {
            url
          }
        }
        paragraphs: field_sections {
          type: __typename
          ...ParagraphBackground
          ...ParagraphBody
          ...ParagraphBookNowBanner
          ...ParagraphFeaturedDestinations
          ...ParagraphFeaturedOffers
          ...ParagraphFeaturedProperties
          ...ParagraphFooterCta
          ...ParagraphFrequentlyAskedQuestions
          ...ParagraphHistory
          ...ParagraphMediaCta
          ...ParagraphMediaTiles
          ...ParagraphOffers
          ...ParagraphOurBrands
          ...ParagraphOurBrandsTeasers
          ...ParagraphPageBanner
          ...ParagraphPageIntro
          ...ParagraphPageTeaser
          ...ParagraphPartnershipBrands
          ...ParagraphSailDates
          ...ParagraphSiteOffers
          ...ParagraphSupportTiles
          ...ParagraphTeaserList
          ...ParagraphPhotoGallery
        }
        offersParagraphs: field_offers_page {
          type: __typename
          ...ParagraphPageBanner
          ...ParagraphPageIntro
        }
      }
    }
    siteOffers: allParagraphSiteOffers(limit: 2) {
      edges {
        node {
          relationships {
            field_node_offers {
              id
              title
              field_subtitle
              field_promo_code
              path {
                alias
              }
              field_external_link {
                title
                uri
                url
              }
              description: body {
                summary
                processed
              }
              relationships {
                field_rate_code {
                  code
                }
                field_teaser_image {
                  relationships {
                    field_media_image {
                      publicUrl
                      gatsbyImage(
                        width: 490
                        height: 360
                        placeholder: BLURRED
                        formats: [AUTO, WEBP]
                      )
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    allRlhsite(filter: { machine_name: { eq: "sonesta" } }) {
      edges {
        node {
          field_filter_selected
        }
      }
    }
  }
`;
export default BrandPage;
