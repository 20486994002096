import React from "react";
import { IBrandOffersProps } from "./BrandOffersProps";
import { IOffers } from "../../../@types/IOffers";
import { useStaticQuery, graphql } from "gatsby";
import { getParagraph } from "../../../utils/paragraphHelpers";
import PageOfferList from "../../common/Offers/PageOfferList/PageOfferList";
import SEO from "../../global/SEO/SEO";
import Container from "react-bootstrap/Container";

const BrandOffers: React.FC<IBrandOffersProps> = (props) => {
  const brand = props.brand;
  const data = useStaticQuery(graphql`
    {
      page: nodePage(path: { alias: { eq: "/v1/offers" } }) {
        id
        relationships {
          offers: field_sections {
            type: __typename
            ...ParagraphSiteOffers
          }
        }
      }
    }
  `);
  const offers: IOffers[] = [];
  const paragraphs = brand.relationships.offersParagraphs.map(getParagraph);
  const offersParagraph = brand.relationships.paragraphs.find(
    (paragraph: { type: string }) => paragraph.type === "paragraph__offers"
  );
  // Add brand level offers.
  const brandOffers = offersParagraph?.relationships?.offers;
  const brandPath = brand?.path.alias || "";
  if (offersParagraph !== undefined) {
    brandOffers.forEach((offer: IOffers) => {
      if (
        offer.path?.alias &&
        brandPath &&
        offer.path.alias.indexOf(brandPath) == -1
      ) {
        offer.path.alias = `${brandPath}${offer.path.alias}`;
      }
      offers.push(offer);
    });
  }
  const siteOffers = data.page.relationships.offers.find(
    (siteOffer: { type: string }) => siteOffer.type === "paragraph__site_offers"
  );
  // Backfill with sitewide offers.
  if (siteOffers !== undefined) {
    siteOffers.relationships.offers.forEach((siteOffer: IOffers) => {
      // Only use bookable offers.
      if (siteOffer.field_promo_code) {
        offers.push(siteOffer);
      }
    });
  }
  const sonestaOnlyoffers =
    offers &&
    offers.filter(
      (offer: {
        relationships: {
          field_site_list?: { machine_name: string | undefined };
        };
      }) =>
        offer.relationships.field_site_list?.machine_name ==
        process.env.GATSBY_SITE_MACHINE_NAME
    );
  const description = brand.description !== null ? brand.description : null;
  return (
    <React.Fragment>
      <SEO
        title={`${brand.name} Offers`}
        description={description !== null ? brand.description.summary : ""}
      />
      <h1 className="visually-hidden">{`${brand.name} Offers`}</h1>
      {paragraphs}
      {offers.length && (
        <Container className="pt-5 pb-5 mb-5 ">
          <PageOfferList offers={sonestaOnlyoffers} />
        </Container>
      )}
    </React.Fragment>
  );
};

export default BrandOffers;
